<template>
  <div class="register flex-c">
    <div class="register_box flex-r">
      <div class="register_box_lf flex-c">
        <div class="welcome  fc-white">
          <span class="fz-18">欢迎来到</span>
          <p class="fz-24">{{ $store.state.company }}</p>
        </div>
      </div>
      
      <div class="register_box_rt">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0">
        	
          <el-form-item label="">
            <h2 class="text-align-center">设置密码</h2>
          </el-form-item>
          
          <el-form-item label="" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入手机号码" maxlength="11" prefix-icon="el-icon-phone">
              <i slot="prefix" class="iconfont icon-phone"></i>
            </el-input>
          </el-form-item>
          
          <el-form-item label="" prop="oldPassword">
            <el-input  v-model.number="ruleForm.oldPassword"  placeholder="请输入原密码" prefix-icon="el-icon-key">
              <i slot="prefix" class="iconfont icon-password"></i>
            </el-input>
          </el-form-item>
          
          <el-form-item label="" prop="password">
            <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码" prefix-icon="el-icon-key">
              <i slot="prefix" class="iconfont icon-password"></i>
            </el-input>
          </el-form-item>

          <el-form-item label="" prop="password2">
            <el-input v-model="ruleForm.password2" type="password" placeholder="请再次输入密码" prefix-icon="el-icon-key">
              <i slot="prefix" class="iconfont icon-password"></i>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" class="register--btn" @click="submit('ruleForm')">设置成功</el-button>
          </el-form-item>
          <div class="account--oper text-align-center fz-12">
            <span class="fz-cl-light">已有账号?</span>
            <a class="fz-cl-main" @click.stop="jumpPage('/login')">立即登录</a>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  import { getVerificCode, changePsd } from "@/api/resetpsd";
  export default {
    name: "Register",
    components: {},
    props: {},
    data() {
      const phoneCodeVerify = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入手机号码"));
        } else if (/^[0-9]{11}$/.test(value) === false) {
          return callback(new Error("请输入正确格式的11位手机号码"));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          name: "",
          code: null,
          password: ""
        },
        rules: {
          name: [{ required: true, validator: phoneCodeVerify, trigger: "blur" }],
          oldPassword: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
          password: [{ required: true, message: "请输入密码", trigger: "blur" }],
          password2: [{ required: true, message: "请输入确认密码", trigger: "blur" }]
        },
        btnText: "获取验证码",
        disabledBtn: false,
        time: 60
      };
    },
    //计算属性
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    //keep-alive 组件激活时调用
    activated() { },
    //keep-alive 组件停用时调用。
    deactivated() { },
    //方法
    methods: {
      //提交注册
      submit(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            if(this.ruleForm.password != this.ruleForm.password2){
              this.$message.error("两次密码输入不一致，请重新输入")
              return
            }
            this.resetPsdStatus(this.ruleForm.name,this.ruleForm.oldPassword,this.ruleForm.password)
            
          } else {
            //console.log("error submit!!");
            return false;
          }
        });
      },
      //1.发送验证码
      sendSms() {
        if (this.ruleForm.name) {
        	this.getVerificPhoneCode(this.ruleForm.name)
        } else return this.$message.warning("请输入手机号");
      },
      //2.倒计时
      countTime: function () {
        let _that = this;
        if (this.time > 0) {
          this.time -= 1;
          this.btnText = this.time + "秒后重发";
          setTimeout(_that.countTime, 1000);
        } else {
          this.time = 60;
          this.btnText = "获取验证码";
          this.disabledBtn = false;
        }
      },
      jumpPage(url) {
        this.$router.push({ path: url, query: {} })
      },
      getVerificPhoneCode(phoneNumber){
 	        return new Promise((resolve, reject) => {
		        getVerificCode(phoneNumber).then(res => {
			            if(res.code === 1){
			            	this.$message.success("短信验证码发送成功,请等待...");
			            	this.disabledBtn = true;
                    this.countTime();
                    this.ruleForm.code=""
			            }
			            resolve(res)
		        }).catch(error => {
		             reject(error)
		          })
		      })
      },
      resetPsdStatus(username,oldPassword,newpsd){
   	        return new Promise((resolve, reject) => {
		        changePsd(username,oldPassword,newpsd).then(res => {
			            if(res.code === 1){
			            		this.$message.success("密码修改成功");
			            	
			            		this.$router.push({path:'/login',query:{
			            			username:this.ruleForm.name,
			            		  newpsd:this.ruleForm.password
			            		}})
			             }
			            resolve(res)
		        }).catch(error => {
		             reject(error)
		          })
		      })
      }
    }
  };
</script>
<style scoped lang="scss">
  @import "../assets/scss/colors.scss";
</style>