import request from "@/utils/request";
export function getVerificCode(mobile) {
  return request({
    url: "/oilCard/api/captcha",
    method: "post",
    data: {
      mobile
    }
  })
}

export function resetPsd(username,code,password) {
	return request({
	    url: "/oilCard/api/account/forget-password/reset",
	    method: "post",
	    data: {
	      username,
	      code,
	      password
	    }
	});
}

export function changePsd(username,oldPassword,password) {
	return request({
	    url: "/oilCard/api/account/change-password",
	    method: "post",
	    data: {
	      username,
	      oldPassword,
	      password
	    }
	});
}
